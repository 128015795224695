<template>
  <div class="relative">
    <textarea
      :data-cy="`textarea-${name}`"
      v-model="inputValue"
      class="w-full border-gray-200 placeholder-gray-400 rounded text-xs text-gray-600"
      :class="{ 'pb-8': characterLimit, 'resize-none': noresize }"
      v-bind="$attrs"
      :name="name"
      :placeholder="placeholder"
      :rows="rows"
      :readonly="readonly"
      :maxlength="characterLimit || undefined"
      :disabled="disabled"
      @input="handleInput"
      @focus="focused = true"
      @blur="onBlur"
    />
    <label
      v-show="!isNil(inputValue) && String(inputValue).length && showLabel"
      for="input"
      class="absolute top-0 left-0 -mt-3 text-xs bg-white leading-6 ml-2-5 px-1 z-1"
      :class="
        errorMessage
          ? 'text-red-600'
          : focused
            ? 'text-blue-500'
            : 'text-gray-400'
      "
    >
      {{ placeholder }}
    </label>
    <div
      v-if="characterLimit"
      class="absolute bottom-0 left-0 mb-2 ml-3 bg-white pb-1 w-3/4 flex flex-row items-center"
    >
      <circle-progress
        :size="20"
        :border-width="3"
        :border-bg-width="3"
        :percent="percentageUsed"
        fill-color="#3769D6"
      />
      <div class="ml-2 text-xxs font-semibold">
        {{ inputValue.length }} / {{ characterLimit }}
      </div>
    </div>
  </div>
  <div
    v-show="errorMessage"
    class="pl-2 mb-4 text-xxs sm:text-xs text-red-700 text-left"
  >
    {{ errorMessage }}
  </div>
</template>
<script setup lang="ts">
import { useField } from "vee-validate";
import { watch, ref, computed } from "vue";
import isNil from "lodash/isNil";
import CircleProgress from "vue3-circle-progress";

const emit = defineEmits<{ keyReleased: [value: string] }>();

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  value: {
    type: [String],
    default: ""
  },
  placeholder: {
    type: String,
    default: ""
  },
  rows: {
    type: [Number, String],
    default: 4
  },
  readonly: {
    type: Boolean,
    default: false
  },
  showLabel: {
    type: Boolean,
    default: false
  },
  // this prop is left unused - added to remove warning in iteration
  modelValue: {
    type: String,
    default: () => ""
  },
  characterLimit: {
    type: Number,
    default: 0
  },
  noresize: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean
  }
});

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange
} = useField(props.name, undefined, {
  initialValue: props.value
});

const focused = ref(false);

const percentageUsed = computed(() =>
  inputValue.value.length
    ? (inputValue.value.length / props.characterLimit) * 100
    : 0
);

const onBlur = (e: Event) => {
  handleBlur(e);
  focused.value = false;
};

const handleInput = (e: Event) => {
  emit("keyReleased", (e.target as HTMLInputElement).value);
  handleChange(e);
};

watch(
  () => props.value,
  (newVal) => {
    handleChange(newVal);
    inputValue.value = newVal;
  }
);
</script>
